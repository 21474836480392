@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

a {
  text-decoration: none;
  color: white;
}

.nearby-card a {
  text-decoration: underline;
  color: white;
}
