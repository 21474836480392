.footer {
  padding: 1rem;
  text-align: center;
  overflow: hidden;
}
.footer:hover .footer__signature p {
  animation: shake 0.5s forwards;
}

.line {
  width: "80%";
  height: "20rem";
  background-color: "red";
}

a {
  color: #13a89e;
}

@keyframes shake {
  0% {
    transform: translateX(0px);
    color: rgba(#13a89e, 0.8);
  }
  20% {
    transform: translateX(-5px);
    color: rgba(#13a89e, 0.6);
  }
  40% {
    transform: translateX(5px);
    color: rgba(#13a89e, 0.4);
  }
  60% {
    transform: translateX(-5px);
    color: rgba(#13a89e, 0.6);
  }
  80% {
    transform: translateX(5px);
    color: rgba(#13a89e, 0.8);
  }
  100% {
    transform: translateX(0px);
    color: rgba(#13a89e, 1);
  }
}
